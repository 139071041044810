import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryPlace = ({ data }) => {
  return (
    <SuccessStory
      title="Anonymizing street-level images for settlement improvement in Kenya"
      customerName="PLACE"
      customerLocation="Washington DC, USA"
      customerDescription="PLACE is a non-profit data institution that partners with governments to produce up-to-date, detailed aerial, and street imagery. The data we produce is owned by governments who provide us with a perpetual use license for the PLACE Trust, which is then available to the PLACE Community."
      customerIndustries="Geospatial, Government Service"
      link="https://www.thisisplace.org/"
      logo=""
      style={{
        background: "url(/images/place-sample-image.JPG) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          <a href="http://spatialcollective.com/" target="_blank" rel="noopener noreferrer">Spatial Collective</a>, a PLACE supporter in Kenya was contracted by
          the{" "}
          <a href="https://africasustainabilitymatters.com/world-bank-hands-kenya-150mn-to-upgrade-urban-informal-settlements/" target="_blank" rel="noopener noreferrer">
            World Bank
          </a>{" "}
          to develop technology-driven approaches to support informal settlement improvement in Nairobi, Kenya.
        </strong>
      </p>
      <p>
        To provide mapping data, high-resolution 360-degree street imagery was acquired and employed to geo-tag city
        features and objects across four informal communities in the city. In addition to that, data will require
        updates every 6-12 months.
      </p>
      <p>
        Its community must also agree to the responsible and ethical use of data by signing PLACE’s use agreement which
        includes the <a href="https://ethicalgeo.org/locus-charter/" target="_blank" rel="noopener noreferrer">Locus Charter</a>, where data minimization and
        privacy protection are key principles. To remove personally identifiable information (PII), specifically number
        plates and faces, PLACE sought a robust image anonymization solution so that the imagery hosted in the PLACE
        Trust, and used in a locally developed attribution app, could be appropriately redacted.
      </p>

      <figure className="figure">
        <Img fluid={data.placeKisipImage.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">
          An anonymized panorama image from Nairobi, Kenya by PLACE.
        </figcaption>
      </figure>

      <h2 className="my-3">Solution</h2>
      <p>
        PLACE needed to deliver fully anonymized street imagery to Spatial Collective. After considering several
        solutions PLACE selected Celantur to meet its PII requirements.{" "}
        <a href="https://app.celantur.com/" target="_blank" rel="noopener noreferrer">Celantur's cloud service</a> has produced consistently positive outcomes
        protecting individuals’ privacy on the thousands of 360-degree terrestrial images collected.
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Process data as soon as the street images were uploaded from Nairobi by Spatial Collective
      </JobFeature>
      <JobFeature>Simple usage, no code or any on-premise software required</JobFeature>
      <JobFeature>
        Protecting privacy is a key component of the Locus Charter, a set of common international principles regarding
        the ethical use of geospatial data. Using Celantur's solution demonstrates PLACE’s commitment to these
        principles.{" "}
      </JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryPlace

export const query = graphql`
  query {
    placeKisipImage: file(relativePath: { eq: "place-sample-image.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
